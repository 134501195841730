<template>
  <div>
    <AppModal
      :dialogTitle="dialogTitle"
      :disableClose="true">
      <b-form @submit="editCategory">
        <b-form-group
          label="Category Title"
          label-for="categoryTitle"
          :label-cols="3">
          <b-form-input
            id="categoryTitle"
            aria-describedby="categoryTitle-input"
            type="text"
            placeholder="Enter Category Title"
            autocomplete="off"
            @input="$v.form.categoryTitle.$touch()"
            v-model.trim="$v.form.categoryTitle.$model"
            :state="!$v.form.categoryTitle.$error"
          ></b-form-input>
          <b-form-invalid-feedback id="categoryTitle-input">
            <span v-if="!$v.form.categoryTitle.required">
              Category Title is required.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Thumbnail"
          label-for="thumbnail"
          :label-cols="3">
          <b-form-file
            accept="image/jpeg, image/png"
            @change="previewImage"
            v-model="form.thumbnail"
          ></b-form-file>
        </b-form-group>

        <b-row class="justify-content-center text-center mb-3">
          <b-col cols="6" v-if="preview">
            <p class="mb-1">Thumbnail Preview</p>
            <img
              class="img-fluid preview"
              :src="preview"
              alt="Thumbnail Preview"
            />
          </b-col>
          <b-col cols="6">
            <p class="mb-1">Current Thumbnail</p>
            <img
              class="img-fluid preview"
              :src="data.interact_category_img_url"
              alt="Current Thumbnail"
            />
          </b-col>
        </b-row>

        <div class="d-flex justify-content-end">
          <b-button
            class="m-1"
            type="submit"
            variant="outline-success"
            :disabled="$v.form.$invalid || !isFormEdited">
            Submit
          </b-button>
          <b-button
            class="m-1"
            variant="outline-danger"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </AppModal>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators';

  export default {
    props : {
      data : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        loading     : false,
        dialogTitle : 'Edit Category',
        form        : {
          categoryTitle : this.data.interact_category_title,
          thumbnail     : null,
        },
        preview      : null,
        isFormEdited : false,
      }
    },
    components : {
      'AppModal'       : () => import('@/components/layout/AppModal'),
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    watch : {
      'form.thumbnail'(val) {
        if (!val)
          this.preview = null;
      },
    },
    methods : {

      /**
       * Edit Category
       * @param e
       */
      editCategory(e) {
        e.preventDefault();
        this.loading = true;

        if (this.form.categoryTitle !== this.data.interact_category_title) {
          this.$http.put('api/interact/category', {
            interCategoryId : this.data.interact_category_id,
            updateFields    : {
              'interact_category_title' : this.form.categoryTitle,
            },
          }).then(() => {
            if (this.form.thumbnail)
              this.uploadFile(this.data.interact_category_id);
            else {
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Edited category successfully!',
              });
              this.loading = false;
              this.$parent.$emit('reload-table');
              this.$emit('close');
            }
          })
        } else if (this.form.thumbnail)
          this.uploadFile(this.data.interact_category_id);
      },

      /**
       * Upload Thumbnail
       * @param categID
       */
      async uploadFile(categID) {
        const formData = new FormData();
        formData.append('file', this.form.thumbnail);
        formData.append('imageType', 'interactCategory');
        formData.append('id', categID);

        try {
          const upload = await this.$http.post(
            '/api/upload/image', formData, {});
          
          if (upload) {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Edited category successfully!',
            });
            this.loading = false;
            this.$parent.$emit('reload-table');
            this.$emit('close');
          }
        } catch(error) {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      /**
       * Preview Image
       * @param event
       */
      previewImage(event) {
        const input = event.target;
        if (input.files) {
          const reader = new FileReader();
          reader.onload = (e) =>
            this.preview = e.target.result;
          this.form.thumbnail = input.files[0];
          reader.readAsDataURL(input.files[0]);
        } else
          this.preview = null;
      },


      formUpdated() {
        this.isFormEdited = false;

        if (this.form.categoryTitle !== this.data.interact_category_title ||
          this.form.thumbnail)
          this.isFormEdited = true;
      },
    },
    created() {
      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    validations : {
      form : {
        categoryTitle : { required },
        thumbnail     : {},
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/scss/settings";
  .preview {
    width: 10rem;
  }
</style>