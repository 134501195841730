<template>
  <div id="interact-categories">
    <h3>Interact</h3>
    <b-row class="mb-2">
      <b-col cols="12" lg="6" xl="6">
        <b-input-group>
          <b-form-input
            placeholder="Search"
            v-model="keyword"
            @keyup.enter="searchCategory"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="searchCategory">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group-append>

          <b-button
            class="ml-2"
            variant="outline-primary"
            type="button"
            @click="resetSearch"
          >
            <i class="fas fa-sync-alt"></i>
          </b-button>

          <b-button
            class="ml-2"
            type="button"
            title="Create New Category"
            variant="outline-success"
            @click="openAddCategory">
            <i class="fas fa-plus"></i> Category
          </b-button>
        </b-input-group>
        
      </b-col>
    </b-row>

    <Vuetable
      data-path="categories"
      pagination-path="pagination"
      ref="vuetable"
      track-by="interact_category_id"
      :api-url="apiUrl"
      :fields="fields"
      :http-options="httpOptions"
      :css="css.table"
      :per-page="10"
      :append-params="moreParams"
      :query-params="{
        sort    : 'sort',
        page    : 'page',
        perPage : 'perPage'
      }"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="showLoader"
      @vuetable:loaded="hideLoader"
    >
      <template class="text-center" slot="images" slot-scope="props">
        <img
          class="categ-thumbnail"
          :src="props.rowData.interact_category_img_url"
          alt="thumbnail"
          width="80"
          height="80"
          v-if="props.rowData.interact_category_img_url"
        />
        <img
          class="categ-thumbnail"
          src="../../../../assets/images/music_category_default.png"
          alt="thumbnail"
          width="80"
          height="80"
          v-else
        />
      </template>
      
      <!-- Action Buttons -->
      <template class="text-center" slot="actions" slot-scope="props">
        <b-button
          class="m-1"
          title="Edit"
          type="button"
          size="sm"
          variant="success"
          @click="openEditCategory(props.rowData)">
          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>
        <b-button
          class="m-1"
          title="Delete"
          type="button"
          size="sm"
          variant="danger"
          @click="deleteCategory(props.rowData)">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </b-button>
      </template>

      <!-- Update Order -->
      <template class="text-center" slot="order" slot-scope="props">
        <select
          class="form-control"
          v-model="props.rowData.order_no"
          @change="changeOrder(props.rowData)"
        >
          <option
            v-for="(value,key) in orderOptions"
            :key="key"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
      </template>
    </Vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <VuetablePaginationInfo ref="paginationInfo"></VuetablePaginationInfo>
      <VuetablePagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      ></VuetablePagination>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
    
    <!-- Modal -->
    <modals-container />
    <v-dialog />
  </div>
</template>

<script>

  // import moment from 'moment-timezone';
  import TableStyle from '../../table-bootstrap-css.js';
  import AddCategoryModal from './AddCategoryModal';
  import EditCategoryModal from './EditCategoryModal';

  export default {
    head : {
      title : {
        inner      : 'Interactive',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        loading  : false,
        keyword  : null,
        maxOrder : 0,
        apiUrl   : process.env.VUE_APP_ROOT_API +
          'api/interact/categoriesAdmin',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [ {
          name       : 'interact_category_id',
          title      : 'Category<br>ID',
          sortField  : 'interact_category_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:images',
          title      : 'Category Thumbnail',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'interact_category_title',
          title      : 'Category Title',
          sortField  : 'interact_category_title',
          direction  : 'desc',
          titleClass : 'text-center',
        }, {
          name       : '__slot:order',
          title      : 'Order',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:actions',
          title      : 'Actions',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
      }
    },
    computed : {
      orderOptions() {
        const options = [];
        for(let i = 1; i <= this.maxOrder; i++)
          options.push(i);
        
        return options;
      },
    },
    components : {
      'AppLoaderAdmin' :
        () => import('@/components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
    },
    methods : {

      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        this.maxOrder = 0;
        const transformed = {};
        transformed.categories = [];

        transformed.pagination = {
          'total'         : data.result.total,
          'per_page'      : data.result.perPage,
          'current_page'  : data.result.currentPage,
          'last_page'     : data.result.lastPage,
          'next_page_url' : data.result.nextPageUrl,
          'prev_page_url' : data.result.prevPageUrl,
          'from'          : data.result.from,
          'to'            : data.result.to,
        };

        for (let i = 0; i < data.result.data.length; i++) {
          transformed.categories.push({
            'interact_category_id' :
              data.result.data[i].interact_category_id,
            'interact_category_title' :
              data.result.data[i].interact_category_title,
            'interact_category_img_url' :
              data.result.data[i].interact_category_img_url,
            'order_no' : data.result.data[i].order_no,
          });

          if (data.result.data[i].order_no > this.maxOrder)
            this.maxOrder = data.result.data[i].order_no;
        }

        return transformed;
      },

      /**
       * Add Category
       */
      openAddCategory() {
        this.$modal.show(AddCategoryModal, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : () => {
            this.refreshTable();
          },
        })
      },

      /**
       * Edit Category
       * @param data
       */
      openEditCategory(data) {
        this.$modal.show(EditCategoryModal, {
          data : data,
        }, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : () => {
            this.refreshTable();
          },
        });
      },

      /**
       * Delete Category
       * @param data
       */
      deleteCategory(data) {
        this.$modal.show('dialog', {
          title : 'Delete Category',
          text  : `Are you sure you want to delete ` +
            `<b>${data.interact_category_title}</b>?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.showLoader();
              this.$http.delete('api/interact/category', {
                data : {
                  interCategoryId : data.interact_category_id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted a category successfully!',
                });
                this.refreshTable();
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              }).finally(() => {
                this.hideLoader();
              });
            },
          }, {
            title : 'No',
          } ],
        })
      },

      /**
       * Change category order
       * @param data
       */
      changeOrder(data) {
        this.showLoader();
        this.$http.post('api/interact/setCategoryOrder', {
          interCategoryId : data.interact_category_id,
          orderNo         : data.order_no,
        }).then(() => {
          this.refreshTable();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated order successfully',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops Something Went Wrong!. Please try again later.',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },

      /**
       * Search Category
       */
      searchCategory() {
        this.moreParams = {
          'filter' : this.keyword,
        };
        this.refreshTable();
      },

      /**
       * Reset Search
       */
      resetSearch() {
        this.keyword = null;
        this.moreParams = {};
        this.refreshTable();
      },

      /**
       * Refresh Playlist Table
       */
      refreshTable() {
        this.$nextTick(() => {
          this.$refs.vuetable.refresh();
        });
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$nextTick(() => {
          this.$refs.pagination.setPaginationData(paginationData);
          this.$refs.paginationInfo.setPaginationData(paginationData);
        });
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$nextTick(() => {
          this.$refs.vuetable.changePage(page);
        });
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.loading = false;
      },
    },
  }
</script>
